.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 1;
    animation-duration: 0.5s;
    animation-name: appear;
}
.popupCloseDiv{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
}
.popup_inner {
    z-index: 3;
    position: absolute;
    max-height: 100%;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: #282c34;
}
.popupHead{
    background-color: #252525;
    width: 100%;
    font-weight: bold;
    height: 5vmin;
}
.popupTitle{
    margin-left: 10px;
    float: left
}
.popupClose{
    float:right;
    margin-right: 10px;
    cursor: pointer ;
}
.popupBody{
    
    max-height: calc(100% - 5vmin);
    overflow-y: scroll;
}
.popupText{
    
    text-align: justify;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.popupBody::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.popupBody {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



@media screen and (max-width: 1450px) {
    .popup_inner{
        left: 10%;
        right: 10%;
    }
}
/*800*/
@media screen and (max-width: 800px) {
    .popup_inner{
        top: 30%;
        bottom: 30%;    
    }
    .popupHead{
        height: 7vmin;
    }
    .popupBody{
        max-height: calc(100% - 7vmin);
    }
}
@media screen and (max-width: 500px) {
    .popup_inner{
        left: 5%;
        right: 5%;
        top: 35%;
        bottom: 35%;
    }
    .popupHead{
        height: 8vmin;
    }
    .popupBody{
        max-height: calc(100% - 8vmin);
    }
}
@media screen and (max-height: 500px) {
    .popupHead{
        height: 8vmin;
    }
}