.about{
    animation-duration: 2s;
    animation-name: appear;
}
@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.contHob{
    margin-top: 2px;
    display: flex;
    flex-flow:row wrap;
    justify-content: center;
    align-items: center;
}
.hobbEl{
    flex: 1 1 25%;
    margin: 5px;
    margin-top: 30px;
}
.hobbEl img{
    max-width: 180px;
    cursor: pointer;
}
h2{
    margin-bottom: 0px;
}