::-webkit-scrollbar {
  width: 10px;
  background-color: #282c348f;
}
::-webkit-scrollbar-thumb {
  background: #8f8f8fc7;
  border-radius: 10px;
}
body{
  background-color: #282c34;
}
h1{
  margin-bottom: 2px;
}
h2{
  margin-top: 2px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 100%;
  width: 100%;
}
.logoContainer{
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .logoContainer {
    animation: app-float infinite 5s linear;
  }
}

.App{
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes app-float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10%);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
.btn{
  position: relative;
  bottom: 0px;
  margin: auto;
  max-width: 100%;
}
.btn:active path{
  fill: #949494;
}
.btn:active rect:nth-child(1){
  fill: #3f3f3f;
}
.btn:active rect:nth-child(2){
  fill: #383838;
}
.tip{
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.548);
  font-size: 12px;
}
a>img{
  max-width: 100%;
}