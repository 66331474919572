.langs{
    animation-duration: 2s;
    animation-name: appear;
}
@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.contLangs{
    margin: 2%;
    display: flex;
    flex-flow:row wrap;
    justify-content: center;
    align-items: center;
}
.langEl{
    flex: 1 1;
    margin: 5px;
}
.langEl img{
    width: 30vmin;
    cursor: pointer;
}