.contact{
    animation-duration: 2s;
    animation-name: appear;
}
@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.contLink{
    margin: 2%;
    display: flex;
    flex-flow:row wrap;
    justify-content: center;
    align-items: center;
}
.lnkEl{
    flex: 2 1 40%;
    margin: 5px;
}
.lnkEl img{
    width: 30vmin;
    cursor: pointer;
    min-width: 180px;
    max-width: 200px;
}
.mail{
    margin-top: 10px;
    width: 100%;
    height: 100%;
}