.intro,.skills{
    animation-duration: 1s;
    animation-name: appear;
}
@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
#fleche{
    height: 8vh;
    width: 100%;
}
#arrow:hover{
    animation: colorchange 3s infinite;
}
.App-header{
    background-color: transparent;
}
@keyframes colorchange{
    0%{
        fill: white;
    }
    25%{
        fill: lightskyblue;
    }
    50%{
        fill: lime;
    }
    75%{
        fill: orange;
    }
    100%{
        fill: white;
    }
}

#tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}
canvas {
    display: block;
}
.contComp{
    margin-top: 2%;
    display: flex;
    flex-flow:row wrap;
    justify-content: center;
    align-items: center;
}
.compEl{
    margin:auto;
    height: 180px;
    width: 180px;
}
.compEl img{
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.skills{
    height: 100%;
}
.skillWrap{
    flex: 1 1 15%;
    margin: 5px;
    max-height: 180px;
    text-align: center;
}
@media ( max-width: 1500px) {
    .skillWrap{
        flex-basis: 20%;
    }
    .compEl{
        height: auto;
        width: auto;
        min-width: 120px;
        min-height: 120px;
        max-width: 180px;
        max-height: 180px;
    }
 }